<template>
  <Layout>
    <b-row class="pb-4">
      <b-col cols="12">
        <h2>Contratos do Prestador de Multas</h2>
      </b-col>
    </b-row>

    <contracts-modal v-if="contractOpen" :contract="contractOpen" />

    <div class="custom-data-table__filters" data-test="custom-data-table__filters">
      <form @submit.prevent="findContracts">
        <b-container fluid class="p-0">
          <b-form-row>
            <b-col xs="3" sm="3">
              <b-form-group
                class="custom-data-table-filter"
                label-for="filter-input-placa"
                label="Placa"
              >
                <b-form-input
                  id="filter-input-placa"
                  v-model="filters.placa"
                  type="text"
                  placeholder="Digite a placa"
                />
              </b-form-group>
            </b-col>
            <b-col xs="2" sm="2">
              <b-form-group
                class="custom-data-table-filter"
                label-for="filter-input-status"
                label="Status"
              >
                <b-form-select
                  v-model="filters.status"
                  :options="status"
                />
              </b-form-group>
            </b-col>
            <b-col xs="2" sm="1">
              <b-form-group>
                <label style="color: transparent">Search</label>
                <b-button
                  type="submit"
                  variant="info"
                  class="custom-data-table__search-btn"
                  block
                >
                  <i class="fa fa-search" />
                </b-button>
              </b-form-group>
            </b-col>
          </b-form-row>
        </b-container>
      </form>
    </div>
    <div v-if="!loading">
      <b-card body-class="animated fadeIn p-0">
        <b-card-body class="p-0">
          <b-table
            responsive
            striped
            hover
            :items="contracts.items"
            :fields="fields"
            @row-clicked="openModal"
          >
            <template
              v-slot:cell(data_inicio)="data"
            >
              {{ data.item.data_inicio | moment('DD/MM/YYYY') }}
            </template>
            <template
              v-slot:cell(data_fim)="data"
            >
              {{ data.item.data_fim | moment('DD/MM/YYYY') }}
            </template>
            <template
              v-slot:cell(data_validacao)="data"
            >
              {{ data.item.data_validacao | moment('DD/MM/YYYY') }}
            </template>
            <template
              v-slot:cell(ultima_atualizacao)="data"
            >
              {{ data.item.ultima_atualizacao | moment('DD/MM/YYYY') }}
            </template>
            <template
              v-slot:cell(status)="data"
            >
              <b-badge :variant="statusVariant(data.item.status)">
                {{ data.item.status }}
              </b-badge>
            </template>
            <template
              v-slot:cell(placa)="data"
            >
              {{ data.item.placa }}
            </template>
            <template #cell(actions)="row">
              <b-button title="editar" size="sm" variant="link"
                        @click="goTo(row.item)"
              >
                <i class="fa fa-edit" />
              </b-button>
            </template>
          </b-table>
        </b-card-body>
        <b-card-body v-if="contracts.errorType || contracts.items.length === 0" class="p-0">
          <content-controll :service="contracts" />
        </b-card-body>
      </b-card>
      <b-pagination
        v-if="!contracts.errorType && contracts.items.length > 0"
        v-model="pagination.current"
        align="center"
        size="md"
        :total-rows="contracts.meta.totalItems"
        :per-page="contracts.meta.itemCount"
      />
    </div>
    <div v-else class="animated fadeIn">
      <content-loading />
    </div>
  </Layout>
</template>

<script>
import Layout from '@layouts/main';
import ContractsModal from './ContractsModal.vue';
import ContentControll from '@components/content-controll';
import ContentLoading from '@components/content-loading';
import { mapActions } from 'vuex';

export default {
  name: 'Contract',
  page: {
    title: 'Contratos',
  },
  components: {
    Layout,
    ContentControll,
    ContentLoading,
    ContractsModal
  },
  data: () => {
    return {
      loading: false,
      contracts: {
        items: [],
      },
      contractOpen: null,
      pagination: {
        current: 1,
        total: 1,
      },
      filters: {
        status: '',
        placa: '',
      },
      status: [{
        value: '',
        text: 'Selecione o status'
      },
      {
        value: 'aprovado',
        text: 'Aprovado'
      },
      {
        value: 'pendente',
        text: 'Pendente'
      },
      {
        value: 'reprovado',
        text: 'Reprovado'
      }],
      fields: [
        {
          key: 'placa',
          label: 'Placa',
          class: 'pw-100 text-left',
        },
        {
          key: 'status',
          label: 'Status de Validação',
          class: 'pw-180 text-center',
        },
        {
          key: 'data_inicio',
          label: 'Data Inicio',
          class: 'pw-140 text-center',
        },
        {
          key: 'data_fim',
          label: 'Data Fim',
          class: 'pw-180 text-center',
        },
        {
          key: 'data_validacao',
          label: 'Data Validação',
          class: "text-center"
        },
        {
          key: 'ultima_atualizacao',
          label: 'Ultima Atualização',
          class: "text-center"
        },
        {
          key: 'actions',
          label: 'Ações',
          class: "text-center"
        }
      ],
    };
  },
  watch: {
    pagination: {
      deep: true,
      handler() {
        this.getAllContracts()
      }
    }
  },
  mounted() {
    this.getAllContracts()
  },
  methods: {
    ...mapActions('tickets', ['getContracts']),
    findContracts () {
      if (this.filters.placa || this.filters.status) {
        this.pagination.current = 1
      }
      this.getAllContracts()
    },
    getAllContracts() {
      this.loading = true;
      this.getContracts({ ...this.pagination, ...this.filters, page_number: this.pagination.current }).then((res) => {
        this.contracts = res;
      }).finally(() => {
        this.loading = false;
      })
    },
    goTo(_item) {
      const driver = _item.url_cnh.split('/')[5].replace('.jpg', '')
      const contrato = _item.url_contrato.split('/')[4].replace('.jpg', '')
      this.$router.push(`drivers/profile/${driver}/bookings/${contrato}`)
    },
    openModal(_item) {
      const driver = _item.url_cnh.split('/')[5].replace('.jpg', '').toString();
      const contrato = _item.url_contrato.split('/')[4].replace('.jpg', '').toString();
      this.contractOpen = { driver, contrato }
      setTimeout(() => {
        this.$root.$emit('bv::show::modal', 'contracts-modal')
      }, 500)
    },
    statusVariant(status) {
      const variant = {
        'aprovado': 'success',
        'pendente': 'warning',
        'reprovado': 'danger'
      };

      return variant[status];
    }
  }
};
</script>

<style lang="scss" scoped>
.pw-100 {
  width: 100px;
}

.pw-140 {
  width: 140px;
}

.pw-180 {
  width: 180px;
}
</style>
