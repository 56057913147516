var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        id: "contracts-modal",
        title: "Documentos de Contratos",
        size: "xl",
        "hide-footer": "",
        lazy: "",
        "no-fade": "",
      },
    },
    [
      _c(
        "div",
        [
          _c(
            "b-container",
            { staticClass: "p-0 bg-white", attrs: { fluid: "" } },
            [
              _vm.contract.driver
                ? _c("driver-header", {
                    attrs: { id: _vm.contract.driver, "reset-profile": false },
                  })
                : _vm._e(),
              !_vm.$apollo.loading
                ? _c(
                    "div",
                    [
                      _c(
                        "b-row",
                        { staticClass: "text-center" },
                        [
                          _c("b-col", [_c("h3", [_vm._v("Contrato")])]),
                          _c("b-col", [
                            _c("h3", [_vm._v("Documento do motorista")]),
                          ]),
                          _c("b-col", [
                            _c("h3", [_vm._v("Documento do carro")]),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            {
                              staticStyle: { cursor: "pointer" },
                              attrs: { title: "Abrir Contrato" },
                              on: {
                                click: function ($event) {
                                  return _vm.openImage(_vm.booking.contract)
                                },
                              },
                            },
                            [
                              _c("b-img", {
                                attrs: {
                                  thumbnail: "",
                                  fluid: "",
                                  src: _vm.booking.contract,
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            {
                              staticStyle: { cursor: "pointer" },
                              attrs: { title: "Abrir Documento do Motorista" },
                              on: {
                                click: function ($event) {
                                  return _vm.openImage(
                                    _vm.booking.driver.cnh_doc.url
                                  )
                                },
                              },
                            },
                            [
                              _c("b-img", {
                                attrs: {
                                  thumbnail: "",
                                  fluid: "",
                                  src: _vm.booking.driver.cnh_doc.url,
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            {
                              staticStyle: { cursor: "pointer" },
                              attrs: { title: "Abrir Documento do Carro" },
                              on: {
                                click: function ($event) {
                                  return _vm.openImage(
                                    _vm.booking.car.document_url
                                  )
                                },
                              },
                            },
                            [
                              _c("b-img", {
                                attrs: {
                                  thumbnail: "",
                                  fluid: "",
                                  src: _vm.booking.car.document_url,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _c(
                    "div",
                    { staticClass: "animated fadeIn" },
                    [_c("content-loading")],
                    1
                  ),
            ],
            1
          ),
          _c("hr"),
          _c(
            "b-button",
            {
              staticClass: "mt-3",
              attrs: { variant: "primary", block: "" },
              on: { click: _vm.goTo },
            },
            [_vm._v(" Editar Contrato ")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }