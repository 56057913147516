<template>
  <b-modal id="contracts-modal" title="Documentos de Contratos"
           size="xl"
           hide-footer lazy no-fade
  >
    <div>
      <b-container fluid class="p-0 bg-white">
        <driver-header
          v-if="contract.driver"
          :id="contract.driver"
          :reset-profile="false"
        />
        <div v-if="!$apollo.loading">
          <b-row class="text-center">
            <b-col>
              <h3>Contrato</h3>
            </b-col>
            <b-col>
              <h3>Documento do motorista</h3>
            </b-col>
            <b-col>
              <h3>Documento do carro</h3>
            </b-col>
          </b-row>
          <b-row>
            <b-col style="cursor: pointer;" title="Abrir Contrato" @click="openImage(booking.contract)">
              <b-img thumbnail fluid :src="booking.contract" />
            </b-col>
            <b-col
              style="cursor: pointer;"
              title="Abrir Documento do Motorista"
              @click="openImage(booking.driver.cnh_doc.url)"
            >
              <b-img thumbnail fluid :src="booking.driver.cnh_doc.url" />
            </b-col>
            <b-col style="cursor: pointer;" title="Abrir Documento do Carro" @click="openImage(booking.car.document_url)">
              <b-img thumbnail fluid :src="booking.car.document_url" />
            </b-col>
          </b-row>
        </div>
        <div v-else class="animated fadeIn">
          <content-loading />
        </div>
      </b-container>
      <hr>
      <b-button variant="primary" class="mt-3" block
                @click="goTo"
      >
        Editar Contrato
      </b-button>
    </div>
  </b-modal>
</template>

<script>
  import ContentLoading from '@components/content-loading';
  import DriverHeader from '@components/drivers/header';
  import gql from 'graphql-tag';

  export default {
    components: {
      DriverHeader,
      ContentLoading
    },
    props: {
      contract: {
        type: Object,
        required: true,
        driver: {
          type: String
        },
        contract: {
          type: String
        }
      }
    },
    data () {
      return {
        loading: true,
      }
    },
    mounted() {
      this.loading = true
    },
    apollo: {
    booking: {
      query: gql `
        query BookingGet($driverId: ID!) {
          bookingsByDriver(driver: $driverId) {
            items {
              car {
                id
                license_plate
                document_url
              }
              contract
              driver {
                id
                name
                status
                cnh_doc {
                  url
                }
              }
            }
          }
        }
      `,
      variables() {
        return {
          driverId: this.contract.driver,
        };
      },
      update({
        bookingsByDriver
      }) {
        const booking = bookingsByDriver.items.find((el) => el.contract.includes(this.contract.contrato))
        this.loading = false;
        return booking;
      },
    },
  },
  methods: {
    goTo() {
      const { driver, contrato } = this.contract
      this.$router.push(`drivers/profile/${driver}/bookings/${contrato}`)
    },
    openImage(img) {
      window.open(img, '_blank');
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
