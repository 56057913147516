var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Layout",
    [
      _c(
        "b-row",
        { staticClass: "pb-4" },
        [
          _c("b-col", { attrs: { cols: "12" } }, [
            _c("h2", [_vm._v("Contratos do Prestador de Multas")]),
          ]),
        ],
        1
      ),
      _vm.contractOpen
        ? _c("contracts-modal", { attrs: { contract: _vm.contractOpen } })
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "custom-data-table__filters",
          attrs: { "data-test": "custom-data-table__filters" },
        },
        [
          _c(
            "form",
            {
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.findContracts.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "b-container",
                { staticClass: "p-0", attrs: { fluid: "" } },
                [
                  _c(
                    "b-form-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { xs: "3", sm: "3" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              staticClass: "custom-data-table-filter",
                              attrs: {
                                "label-for": "filter-input-placa",
                                label: "Placa",
                              },
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  id: "filter-input-placa",
                                  type: "text",
                                  placeholder: "Digite a placa",
                                },
                                model: {
                                  value: _vm.filters.placa,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.filters, "placa", $$v)
                                  },
                                  expression: "filters.placa",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { xs: "2", sm: "2" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              staticClass: "custom-data-table-filter",
                              attrs: {
                                "label-for": "filter-input-status",
                                label: "Status",
                              },
                            },
                            [
                              _c("b-form-select", {
                                attrs: { options: _vm.status },
                                model: {
                                  value: _vm.filters.status,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.filters, "status", $$v)
                                  },
                                  expression: "filters.status",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { xs: "2", sm: "1" } },
                        [
                          _c(
                            "b-form-group",
                            [
                              _c(
                                "label",
                                { staticStyle: { color: "transparent" } },
                                [_vm._v("Search")]
                              ),
                              _c(
                                "b-button",
                                {
                                  staticClass: "custom-data-table__search-btn",
                                  attrs: {
                                    type: "submit",
                                    variant: "info",
                                    block: "",
                                  },
                                },
                                [_c("i", { staticClass: "fa fa-search" })]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      !_vm.loading
        ? _c(
            "div",
            [
              _c(
                "b-card",
                { attrs: { "body-class": "animated fadeIn p-0" } },
                [
                  _c(
                    "b-card-body",
                    { staticClass: "p-0" },
                    [
                      _c("b-table", {
                        attrs: {
                          responsive: "",
                          striped: "",
                          hover: "",
                          items: _vm.contracts.items,
                          fields: _vm.fields,
                        },
                        on: { "row-clicked": _vm.openModal },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "cell(data_inicio)",
                              fn: function (data) {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("moment")(
                                          data.item.data_inicio,
                                          "DD/MM/YYYY"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              },
                            },
                            {
                              key: "cell(data_fim)",
                              fn: function (data) {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("moment")(
                                          data.item.data_fim,
                                          "DD/MM/YYYY"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              },
                            },
                            {
                              key: "cell(data_validacao)",
                              fn: function (data) {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("moment")(
                                          data.item.data_validacao,
                                          "DD/MM/YYYY"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              },
                            },
                            {
                              key: "cell(ultima_atualizacao)",
                              fn: function (data) {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("moment")(
                                          data.item.ultima_atualizacao,
                                          "DD/MM/YYYY"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              },
                            },
                            {
                              key: "cell(status)",
                              fn: function (data) {
                                return [
                                  _c(
                                    "b-badge",
                                    {
                                      attrs: {
                                        variant: _vm.statusVariant(
                                          data.item.status
                                        ),
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(data.item.status) + " "
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                            {
                              key: "cell(placa)",
                              fn: function (data) {
                                return [
                                  _vm._v(" " + _vm._s(data.item.placa) + " "),
                                ]
                              },
                            },
                            {
                              key: "cell(actions)",
                              fn: function (row) {
                                return [
                                  _c(
                                    "b-button",
                                    {
                                      attrs: {
                                        title: "editar",
                                        size: "sm",
                                        variant: "link",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.goTo(row.item)
                                        },
                                      },
                                    },
                                    [_c("i", { staticClass: "fa fa-edit" })]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1473381391
                        ),
                      }),
                    ],
                    1
                  ),
                  _vm.contracts.errorType || _vm.contracts.items.length === 0
                    ? _c(
                        "b-card-body",
                        { staticClass: "p-0" },
                        [
                          _c("content-controll", {
                            attrs: { service: _vm.contracts },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              !_vm.contracts.errorType && _vm.contracts.items.length > 0
                ? _c("b-pagination", {
                    attrs: {
                      align: "center",
                      size: "md",
                      "total-rows": _vm.contracts.meta.totalItems,
                      "per-page": _vm.contracts.meta.itemCount,
                    },
                    model: {
                      value: _vm.pagination.current,
                      callback: function ($$v) {
                        _vm.$set(_vm.pagination, "current", $$v)
                      },
                      expression: "pagination.current",
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _c(
            "div",
            { staticClass: "animated fadeIn" },
            [_c("content-loading")],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }